export default {
  GET(state, data) {
    state.audit = { ...data }
  },
  LIST(state, data) {
    state.audits = data
  },
  SET_AUDIT_FORM(state, data) {
    state.auditForm = { ...data }
  },
}
