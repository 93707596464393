<template>
  <router-view />
</template>
<script>

import store from '@/store'
import auditsModule from '@/store/main/catalog/audits'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'Audit',
  created() {
    this.$emit('updateMenu', 'home-catalog-audit')
  },
  setup() {
    const MODULE_NAME = 'audits'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, auditsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>
